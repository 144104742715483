<template>
  <div>
    <v-row class="nearby-map-container" no-gutters>
      <v-col md="9">
        <vl-map
          :style="{ height: height + 'px' }"
          :load-tiles-while-animating="true"
          :load-tiles-while-interacting="false"
        >
          <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

          <vl-layer-vector :z-index="1">
            <vl-source-vector
              :features.sync="features"
              ident="the-source"
            ></vl-source-vector>

            <vl-style-box>
              <vl-style-stroke color="green"></vl-style-stroke>
              <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
            </vl-style-box>
          </vl-layer-vector>

          <vl-interaction-select
            type="Polygon"
            source="the-source"
            :features.sync="selectedFeatures"
          >
            <vl-style-box>
              <vl-style-stroke color="red"></vl-style-stroke>
              <vl-style-fill color="rgba(255,0,0,0.5)"></vl-style-fill>
            </vl-style-box>
          </vl-interaction-select>
          <vl-layer-tile :z-index="0">
            <vl-source-osm></vl-source-osm>
          </vl-layer-tile>
          <vl-feature> </vl-feature>

          <template v-if="location">
            <vl-feature>
              <vl-geom-point :coordinates="location"></vl-geom-point>
              <vl-style-box>
                <vl-style-circle :radius="10">
                  <vl-style-fill color="rgba(255,255,255,0.9)"></vl-style-fill>
                  <vl-style-stroke color="#ff0000" :width="3"></vl-style-stroke>
                </vl-style-circle>
              </vl-style-box>
            </vl-feature>
          </template>
          <template v-if="projectAreas && coloredProjectAreas">
            <template v-for="(item, index) in coloredProjectAreas">
              <vl-feature
                v-if="item.data.geometry"
                :id="item.data.id"
                :key="index"
              >
                <vl-geom-multi-polygon
                  :coordinates="JSON.parse(item.data.geometry).coordinates"
                ></vl-geom-multi-polygon>
                <vl-style-box>
                  <vl-style-fill :color="item.fillColor"></vl-style-fill>
                  <vl-style-stroke
                    :color="item.borderColor"
                    :width="3"
                  ></vl-style-stroke>
                  <vl-style-text
                    :text="item.data.id.toString()"
                    font="bold 1rem sans-serif"
                    color="#ffffff"
                  ></vl-style-text>
                </vl-style-box>
              </vl-feature>
            </template>
          </template>
        </vl-map>
      </v-col>
      <v-col md="3">
        <v-card class="card-legend" :style="{ height: height + 'px' }">
          <v-card-title elevation="2">
            <v-row no-gutters>
              <div class="label">
                Ausbaugebiete in der Nähe
              </div>
            </v-row>
            <v-row no-gutters>
              <v-select
                v-model="limit"
                :items="[3, 4, 5, 6, 7, 8, 9, 10]"
                hide-details
                solo
              ></v-select>
            </v-row>
          </v-card-title>
          <div class="area-item-container">
            <div class="scroll-container">
              <template v-for="(item, index) in coloredProjectAreas">
                <div
                  :key="index"
                  class="area-item"
                  :style="{
                    'border-left': '10px solid ' + item.borderColor,
                    'background-color': item.fillColor
                  }"
                >
                  <v-row>
                    <v-col>
                      <div class="margin-left-0_5">
                        <b> {{ item.data.id }} {{ item.data.district }}</b><br />
                        Entfernung: {{ item.data.distance }} m
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-overlay :value="loading" :absolute="true">
        <v-progress-circular
          :size="120"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-row>
  </div>
</template>
<script>
import Vue from 'vue';
import proj4 from 'proj4';
import VueLayers from 'vuelayers';
import 'vuelayers/lib/style.css';
import { register } from 'ol/proj/proj4';

import { HTTP } from '@/main/httpClient.js';

proj4.defs(
  'EPSG:3857',
  '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);
register(proj4);

Vue.use(VueLayers, {
  dataProjection: 'EPSG:3857'
});

export default {
  name: 'ProjectsNearbyMap',
  components: {},
  props: {
    address: {
      type: Object,
      required: false,
      default: null
    },
    height: {
      type: Number,
      required: false,
      default: 600
    }
  },
  data: () => ({
    selected: undefined,
    location: undefined,
    projectAreas: undefined,
    projection: 'EPSG:3857',
    center: [1083877.0610837992, 6676315.798540437],
    zoom: 12,
    rotation: 0,
    limit: 5,
    selectedFeatures: [],
    deviceCoordinate: undefined,
    features: [],
    mapPanel: {
      tab: 'state'
    },
    panelOpen: true,
    mapVisible: true,
    loading: false
  }),
  computed: {
    coloredProjectAreas() {
      const returnArray = [];
      const hex2rgba = (hex, alpha = 1) => {
        const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
        return `rgba(${r},${g},${b},${alpha})`;
      };
      if (this.projectAreas) {
        this.projectAreas.forEach((item) => {
          const borderColor = this.getRandomColor();
          const fillColor = hex2rgba(borderColor, 0.2);
          const obj = {
            data: item,
            borderColor: borderColor,
            fillColor: fillColor
          };
          returnArray.push(obj);
        });
      }
      return returnArray;
    },
    selectedProjectArea() {
      let Obj = {};
      if (this.projectAreas && (this.selected || this.selected === 0)) {
        Obj = this.projectAreas[this.selected];
      }
      return Obj;
    }
  },
  watch: {
    limit() {
      this.getProjectAreas();
    },
    selectedFeatures: {
      deep: true,
      handler(newVal) {
        if (this.selectedFeatures && this.selectedFeatures[0]) {
          this.selected = this.projectAreas.findIndex(
            (item) => item.id === this.selectedFeatures[0].id
          );
        }
      }
    },
    selectedProjectArea: {
      deep: true,
      handler(newVal) {
        this.$emit('changeSelection', newVal);
      }
    },
    address: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.getProjectAreas();
        }
      },
      immediate: true
    }
  },
  methods: {
    getProjectAreas() {
      if (
        this.address.zipCode &&
        this.address.street &&
        this.address.houseNumber
      ) {
        this.loading = true;
        HTTP.get('/past/geo/project-areas-nearby', {
          headers: this.headers,
          params: {
            zip: this.address.zipCode,
            street: this.address.street,
            houseno: this.address.houseNumber,
            housenoSuppl: this.address.houseNumberSupplement,
            limit: this.limit
          }
        })
          .then((response) => {
            this.location = JSON.parse(response.data.location).coordinates;
            this.center = this.location;
            this.projectAreas = response.data.projectAreas;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }
    },
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  }
};
</script>
<style scoped lang="scss">
.area-item {
  padding: 10px 0;
}
.nearby-map-container {
  position: relative;
}
.area-item-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 112px);
}
.scroll-container {
  height: 100%;
}
</style>
